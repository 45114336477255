import MainLayout from '../layouts/layout';
import styles from '../styles/WearerDashboard.module.css';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
// import axios from 'axios';
import { Row, Space, Col, Input, message, Button, Modal, InputNumber, DatePicker } from 'antd'
import { friendMessageColumns, friendsColumns, userColumns } from '../components/tables/wearerColumns';
import { useAuth, checkAuth } from "../authContext";
import TableComponent from '../components/tables/table'
import useQuery from '../utils/hooks/UseQuery';
import ComandsComponent from '../components/Comands';
import WearerInfo from '../components/WearerInfo';
import WearerSettings from '../components/WearerSettings';
import WearerMainCard from '../components/WearerMainCard';
import AppVersionsCard from '../components/AppVersionsCard';
import WearerLastConnectionCard from '../components/WearerLastConnectionCard';
import WearerBatteryHistory from '../components/WearerBatteryHistory';
import WearerContactCard from '../components/WearerContactCard';
// import SimMainCard from '../components/SimMainCard';
import {
  getWearer,
  getWatchUsers,
  getFriends,
  getChatUser,
  getChatWearer,
  getBatteryHistory,
  getSimInfo,
  getTcpOptions,
  updateWatchUserStatus,
  deleteWatchUser,
  changeAdmin,
  editWearer,
  updateWearerSettings,
  getWatchBuildInfo
} from '../services/wearerService';
import WearerSIMCard from '../components/WearerSIMCard';
// import SimPlanCard from '../components/SimPlanCard';
import dayjs from 'dayjs';
import axios from 'axios';

const { Search } = Input;

export default function WearerDashboard() {
  const { tokens } = useAuth();
  const [messageApi, contextHolder] = message.useMessage();
  const key = 'updatable';
  const [inputValue, setInputValue] = useState('');
  const [friendMessageData, setFriendMessageData] = useState([]);
  const [friendData, setFriendData] = useState([]);
  const [userMessageData, setUserMessageData] = useState([]);
  const [users, setUsers] = useState([]);
  const [batteryHistory, setBatteryHistory] = useState([]);
  let query = useQuery();
  const [wearer, setWearer] = useState({});
  const [watchSettings, setWatchSettings] = useState({});
  const [simData, setSimData] = useState({});
  const [options, setOptions] = useState([
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    // ... more options
  ]);
  const [watchUserColumnsAux, setWatchUserColumnsAux] = useState([]);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [firstNameInput, setFirstNameInput] = useState('');
  const [lastNameInput, setLastNameInput] = useState('');
  const [phoneInput, setPhoneInput] = useState('');
  const [heightInput, setHeightInput] = useState('');
  const [weightInput, setWeightInput] = useState('');
  const [heartsInput, setHeartsInput] = useState('');
  const [birthDayInput, setBirthDayInput] = useState(new Date());
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
  const [type, setType] = useState('');
  const [objectIdToDelete, setObjectIdToDelete] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [objectIdToGiveAdmin, setObjectIdToGiveAdmin] = useState('');
  const [watchBuildData, setWatchBuildData] = useState('');
  const [watchId, setWatchId] = useState('');



  const navigate = useNavigate();

  const { state } = useLocation()
  let { imei } = state

  // Autenticación
  useEffect(() => {
    if (!tokens || !checkAuth(tokens)) {
      navigate('/login');
    }
  }, [tokens, navigate]);

  // Fetch Wearer Info
  useEffect(() => {
    const deviceId = query.get('deviceId');
    if (query.get('imei')) {
      imei = query.get('imei')
    }
    // const imei = query.get('imei');
    if (!deviceId && !imei) {
      navigate('/not-found');
      return;
    }
    let params = {};
    if (deviceId) {
      params = { deviceId };
    } else if (imei) {
      params = { imei };
    }

    getWearer(params, tokens.AccessToken).then((response) => {
      console.log('Wearer Response:', response.data);
    // Ahora puedes inspeccionar la respuesta para ver dónde está el id
      if (!response.data || response.data.data.length === 0) {
        navigate('/not-found');
        return;
      }
      const wearerData = response.data.data[0]
      setWearer(wearerData);
      setWatchSettings(response.data.includes[0].settings);
      setWatchId(wearerData.objectId)

    }).catch(console.error);
    
    getWatchUsers(params, tokens.AccessToken).then((response) => {
      setUsers(response);
    }).catch(console.error);


    getSimInfo(imei, null, tokens.AccessToken).then((response) => {
      if (!response.data || response.data.length === 0) {
        setSimData({})
      } else {
        const body = response.data.data.results[0];
        console.log("getSimInfo trae: ", body);
        
        const simCard = {
          iccId: body.sim.iccId,
          plan: body.plan,
          remainingTrialDays: response.data.data.remainingTrialDays,
          providerName: body.sim.mnoProvider.name,
          phone: body.msisdn,
          state: body.status,
          networkProvider: body.sim.networkOperator.name,
        };
        setSimData(simCard)
      }
    }).catch(console.error)
    setWatchUserColumnsAux(userColumns)
  }, [query, navigate, tokens])

  // Fetch Wearer Friends
  useEffect(() => {
    const deviceId = query.get('deviceId');
    const imei = query.get('imei');
    let params = {};
    if (deviceId) {
      params = { deviceId };
    } else if (imei) {
      params = { imei };
    }
    getFriends(params, deviceId, imei, tokens.AccessToken).then((response) => {
      setFriendData(response);
    });
  }, [query, wearer, tokens])

  // Fetch Chat Users
  useEffect(() => {
    const deviceId = query.get('deviceId');
    const imei = query.get('imei');
    let params = {};
    if (deviceId) {
      params = { deviceId };
    } else if (imei) {
      params = { imei };
    }
    getChatUser(params, tokens.AccessToken).then((response) => {
      setUserMessageData(response);
    }).catch(console.error);
  }, [query, wearer, tokens])

  //Fetch Build info
  useEffect(() => {
    if (watchId) {
      getWatchBuildInfo(watchId, tokens.AccessToken).then((response) => {
        const buildInfo = response.data.data
        const parsedBuildInfo = JSON.parse(buildInfo);

        console.log("Build Info:", parsedBuildInfo.buildNumber)
        setWatchBuildData(parsedBuildInfo);
        setWatchId(watchId)
      }).catch(console.error);
    }
  }, [watchId, tokens]);
  // Fetch Chat Wearer
  useEffect(() => {
    const deviceId = query.get('deviceId');
    const imei = query.get('imei');
    let params = {};
    if (deviceId) {
      params = { deviceId };
    } else if (imei) {
      params = { imei };
    }
    getChatWearer(params, tokens.AccessToken).then((response) => {
      setFriendMessageData(response);
    }).catch(console.error);
  }, [query, wearer, tokens])

  // Fetch Battery History
  useEffect(() => {
    if (wearer) {
      if (wearer.deviceId) {
        getBatteryHistory(wearer.deviceId, tokens.AccessToken).then((response) => {
          setBatteryHistory(response);
        }).catch(console.error);
      }
    }
  }, [query, wearer, tokens])

  // Fetch TCP Options
  useEffect(() => {
    getTcpOptions(tokens.AccessToken).then((response) => {
      const arr = response.data.data.reduce((acc, item) => {
        return acc.concat([{ value: item[0], label: item[1], params: item[2] }])
      }, [])
      setOptions(arr)
    }).catch(console.error)
  },[])

  useEffect(() => {
    let cols = userColumns;
    let authAction = cols[cols.length - 3];
    let deleteAction = cols[cols.length - 2];
    let giveAdminAction = cols[cols.length - 1];
    authAction.render = (row) => {
      return (
        <button onClick={async () => {
          console.log(row)
          await updateWatchUser(row.watchUserId, row.authorized == 'Si' ? false : true)
      }} 
      disabled={row.authorized == 'Si'} 
      style={{backgroundColor: row.authorized == 'Si' ? '#FB88AF' : '#32B8C0', color: 'white', padding: '0.25rem', borderRadius: '1rem', width: '100px'}}>
      {row.authorized == 'Si' ? "Autorizado" : "Autorizar"}
      </button>
      )
    }
    deleteAction.render = (row) => {
      if (row?.objectId === wearer?.userInCharge?.objectId) {
        return (
          <div>
            Admin
          </div>
        )
      }
      return (
        <button onClick={async () => {
          console.log(row)
          if (row?.watchUserId) { 
            deleteWatchUserBtn(row.watchUserId);
          }
      }}
      style={{backgroundColor: '#F93C7C', color: 'white', padding: '0.25rem', borderRadius: '1rem', width: '100px'}}>Eliminar
      </button>
      )
    }
    giveAdminAction.render = (row) => {
      if (row && row.objectId && wearer && wearer.userInCharge && wearer.userInCharge.objectId) {

      if (row.objectId === wearer.userInCharge.objectId) {
        return (
          <div>
            Admin
          </div>
        )
      }}
      return (
        <button onClick={async () => {
          console.log(row)
          giveAdminBtn(row.objectId)
      }}
      style={{backgroundColor: row.admin == 'Si' ? '#F93C7C' : '#32B8C0', color: 'white', padding: '0.25rem', borderRadius: '1rem', width: '100px'}}>{row.admin == 'Si' ? "Quitar admin" : "Dar admin"}
      </button>
      )
    }
    cols[cols.length - 3] = authAction;
    cols[cols.length - 2] = deleteAction;
    cols[cols.length - 1] = giveAdminAction;
    setWatchUserColumnsAux(cols)
  }, [query, wearer, tokens])

 // #region Handle Refreshes


  const handleWatchUserRefresh = () => {
    openMessageApi('Loading...', 'loading');
    const params = { deviceId: wearer.deviceId, imei: wearer.imei };
    getWatchUsers(params, tokens.AccessToken).then((response) => {
      openMessageApi('Loaded!', 'success');
      setUsers(response);
    }).catch(() => {
      openMessageApi('Error fetching data!', 'error');
    });
  }

  const handleFriendsRefresh = () => {
    openMessageApi('Loading...', 'loading');
    const params = { deviceId: wearer.deviceId, imei: wearer.imei };
    getFriends(params, params.deviceId, params.imei, tokens.AccessToken).then((response) => {
      openMessageApi('Loaded!', 'success');
      setFriendData(response);
    }).catch(() => {
      openMessageApi('Error fetching data!', 'error');
    });
  }

  const handleChatUserRefresh = () => {
    openMessageApi('Loading...', 'loading');
    const params = { deviceId: wearer.deviceId, imei: wearer.imei };
    getChatUser(params, tokens.AccessToken).then((response) => {
      openMessageApi('Loaded!', 'success');
      setUserMessageData(response);
    }).catch(() => {
      openMessageApi('Error fetching data!', 'error');
    });
  }

  const handleChatWearerRefresh = () => {
    openMessageApi('Loading...', 'loading');
    const params = { deviceId: wearer.deviceId, imei: wearer.imei };
    getChatWearer(params, tokens.AccessToken).then((response) => {
      openMessageApi('Loaded!', 'success');
      setFriendMessageData(response);
    }).catch(() => {
      openMessageApi('Error fetching data!', 'error');
    });
  }

  const handleBatteryHistoryRefresh = () => {
    openMessageApi('Loading...', 'loading');
    if (wearer) {
      if (wearer.deviceId) {
        getBatteryHistory(wearer.deviceId, tokens.AccessToken).then((response) => {
          openMessageApi('Loaded!', 'success');
          
          setBatteryHistory(response);
        }).catch(() => {
          openMessageApi('Error fetching data!', 'error');
        });
      }
    }
  }

  const handleSIMRefresh = () => {
    openMessageApi('Loading...', 'loading');

    let imeiValue;

    if (imei) {
      imeiValue = imei;
    } else if (wearer && wearer.imei) {
      imeiValue = wearer.imei
    } else return;

    getSimInfo(imeiValue, null, tokens.AccessToken).then((response) => {
      openMessageApi('Loaded!', 'success');
      
      if (!response.data || response.data.length === 0) {
        setSimData({})
      } else {
        const body = response.data.data[0];
        const simCard = {
          planName: body.plan.title,
          providerName: body.sim.mnoProvider.name,
          phone: body.msisdn,
          state: body.status,
          networkProvider: body.sim.networkOperator.name,
        };
        setSimData(simCard)
      }
    }).catch(() => {
      openMessageApi('Error fetching data!', 'error');
    });
  }

  const handleWearerInfoRefresh = () => {
    const params = {
      deviceId: wearer.deviceId,
      imei: wearer.imei,
    }
    openMessageApi('Loading...', 'loading');
    
    getWearer(params, tokens.AccessToken).then((response) => {
      if (!response.data || response.data.data.length === 0) {
        openMessageApi('Error fetching data!', 'error');
        return;
      }
      openMessageApi('Loaded!', 'success');
      setWearer(response.data.data[0]);
      setWatchSettings(response.data.includes[0].settings);
    }).catch(() => {
      openMessageApi('Error fetching data!', 'error');
    });
  }
  // #endregion

  async function navSimDashboard() {
    openMessageApi('Loading...', 'loading');
    navigate(`/sim/dashboard?imei=${imei}`, {state: { imei }});
  }

  async function onSearch(value) {
    openMessageApi('Loading...', 'loading');
    navigate(`/?searchTxt=${value}`);
  }

  async function updateWearer(deviceId, imei) {
    let payload;
    try {
      if (deviceId) {
        payload = { deviceId }
      } else if (imei) {
        payload = { imei: imei }
      }
      getWearer(payload, tokens.AccessToken).then((response) => {
        if (!response.data || response.data.data.length === 0) {
          navigate('/not-found');
          return;
        }
        setWearer(response.data.data[0]);
        setWatchId(response.data.data[0].objectId)
      }).catch(console.error);
    } catch(error) {
      console.error(error)
    }
  }

  async function resetWatch(deviceId, imei) {
    try {
        openMessageApi('Loading...', 'loading')
        if (!deviceId && imei) {
            deviceId = imei.slice(4, 14);
        } else if (!deviceId && !imei) {
          throw new Error('Error, no deviceId or imei provided.');
        }
        const response = await axios.post(
            process.env.REACT_APP_BACKEND_HOST +'/wearer/resetWearer',
            { deviceId },
            { 
                headers: { Authorization: `Bearer ${tokens.AccessToken}` }
            }
        );
        if (response.status === 201 || response.status === 200) {
            openMessageApi('Success!', 'success')
            updateWearer(deviceId, imei)
        } else {
            openMessageApi(`Error ${response.status}: ${response.data.error}`, 'error')
        }
    } catch (error) {
        openMessageApi(`Error: ${error.message}`, 'error')
    }
  }

  async function updateWatchUser(watchUserObjectId, active) {
    try {
      openMessageApi('Loading...', 'loading')
      const response = await updateWatchUserStatus(watchUserObjectId, active, tokens.AccessToken);
      if (response.status === 201 || response.status === 200) {
          openMessageApi('Success!', 'success')
          handleWatchUserRefresh()
      } else {
          openMessageApi(`Error ${response.status}: ${response.data.error}`, 'error')
      }
    } catch (error) {
        openMessageApi(`Error: ${error.message}`, 'error')
    }
  }

  function deleteWatchUserBtn(watchUserObjectId) {
    console.log(watchUserObjectId)
    setIsConfirmationModalVisible(true);
    setType('deleteWatchUser');
    setModalTitle('Estas seguro de eliminar este usuario?');
    setObjectIdToDelete(watchUserObjectId);
  }
  
  function giveAdminBtn(newAdminObjectId) {
    console.log(newAdminObjectId)
    setIsConfirmationModalVisible(true);
    setType('giveAdmin');
    setModalTitle('Estas seguro de darle permisos de administrador a este usuario?');
    setObjectIdToGiveAdmin(newAdminObjectId);
    console.log(objectIdToGiveAdmin)
  }


  const openMessageApi = (message, type, duration=2) => {
    if (type === 'loading') {
      messageApi.open({
        key,
        type,
        content: message,
      });
    } else {
      messageApi.open({
        key,
        type,
        content: message,
        duration,
      });
    }
  }

  const handleOpenEditWearer = () => {
    console.log("Edit Wearer")
    setFirstNameInput(wearer.firstName ?? "");
    setLastNameInput(wearer.lastName ?? "");
    setPhoneInput(wearer.phone ?? "");
    setHeightInput(wearer.height ?? 1);
    setWeightInput(wearer.weight ?? 1);
    setHeartsInput(wearer.hearts ?? 0);
    const birthday = wearer.birthday ?? new Date()
    const birthdayIso = birthday.iso ?? new Date().toISOString();
    setBirthDayInput(dayjs(birthdayIso));
    setIsEditModalVisible(true);
  }

  const handleOkEditModal = async () => {
    try {
      openMessageApi('Loading...', 'loading')
      const response = await editWearer(wearer.objectId, {
        firstName: firstNameInput,
        lastName: lastNameInput,
        phone: phoneInput,
        height: heightInput,
        weight: weightInput,
        hearts: heartsInput,
        birthday: birthDayInput
      }, tokens.AccessToken);
      setWearer(response.data.data);
      setIsEditModalVisible(false);
      openMessageApi('Success!', 'success')
    } catch(error) {
      openMessageApi(`Error: ${error.message}`, 'error')
    }
  }

  const handleCancelEditModal = () => {
    setIsEditModalVisible(false);
  }

  const handleOkConfirmModal = async () => {
    try {
      openMessageApi('Loading...', 'loading')
      setIsConfirmationModalVisible(false);
      if (type === 'deleteWatchUser') {
        await deleteWatchUser(objectIdToDelete, tokens.AccessToken);
        handleWatchUserRefresh();
        setObjectIdToDelete('');
      } else if (type === 'giveAdmin') {
        await changeAdmin(wearer.deviceId, objectIdToGiveAdmin, tokens.AccessToken);
        handleWearerInfoRefresh();
        setObjectIdToGiveAdmin('');
      }
      setType('');
      openMessageApi('Success!', 'success')
    } catch (error) {
      openMessageApi(`Error: ${error.message}`, 'error')
    }
  }

  const handleCancelConfirmModal = () => {
    setIsConfirmationModalVisible(false);
    setObjectIdToDelete('');
  }

  const editWatchSettings = async (watchSettings) => {
    openMessageApi('Loading...', 'loading')
    try {
      await updateWearerSettings(wearer.deviceId, watchSettings, tokens.AccessToken);
      // setWatchSettings(response.data.data.settings);
      handleWearerInfoRefresh();
      openMessageApi('Success!', 'success')
    } catch (error) {
      openMessageApi(`Error: ${error.message}`, 'error')
    }
  }

  return (
    <MainLayout>


    <Modal title={modalTitle} onOk={handleOkConfirmModal} onCancel={handleCancelConfirmModal} open={isConfirmationModalVisible}/>

      <Modal title="Editar Reloj"  onOk={handleOkEditModal} onCancel={handleCancelEditModal} open={isEditModalVisible}  footer={[
          <Button key="back" onClick={handleCancelEditModal}>
            Return
          </Button>,
          <Button key="submit" type="primary" className={styles.submitBtn} onClick={handleOkEditModal}>
            OK
          </Button>,
        ]}>
          <table>
            <tr>
              <td>FirstName:</td>
              <td><Input value={firstNameInput} onChange={e => setFirstNameInput(e.target.value)} /></td>
            </tr>
            <tr>
              <td>LastName:</td>
              <td><Input value={lastNameInput} onChange={e => setLastNameInput(e.target.value)} /></td>
            </tr>
            <tr>
              <td>Phone:</td>
              <td><Input value={phoneInput} onChange={e => setPhoneInput(e.target.value)} /></td>
            </tr>
            <tr>
              <td>Height:</td>
              <td><InputNumber min={1} keyboard={true} value={heightInput} onChange={e => setHeightInput(e)} /></td>
            </tr>
            <tr>
              <td>Weight:</td>
              <td><InputNumber min={1} keyboard={true} value={weightInput} onChange={e => setWeightInput(e)} /></td>
            </tr>
            <tr>
              <td>Hearts:</td>
              <td><InputNumber min={0} keyboard={true} value={heartsInput} onChange={e => setHeartsInput(e)} /></td>
            </tr>
            <tr>
              <td>Birthday:</td>
              <td><DatePicker value={birthDayInput} onChange={(_, dateString) => setBirthDayInput(dayjs(dateString))} /></td>
            </tr>
          </table>
      </Modal>

      <div style={{ padding: 20 }}>
        <div style={{ display: "flex", alignItems: "flex-start", marginBottom: 20 }}>
          { contextHolder }
          <Search placeholder="Buscar reloj por imei o deviceId" onSearch={onSearch} value={inputValue} onChange={(e) => setInputValue(e.target.value)} style={{ width: 500, padding: 5 }} />
          <Button onClick={() => navigate(-1)} style={{ marginLeft: '15px', marginTop: '5px' }}>Go Back</Button>
        </div>
        <Space direction="vertical" size={24} style={{ display: 'flex' }}>
          <Row gutter={[24, 32]}>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>

              {/* Dimensiones 240 + 24 + 424 + 24 + 256 = 968 */}
              <Space direction="vertical" size={24} style={{ display: 'flex' }}>

                {/* Nombre, numero, imei: card principal */}
                <WearerMainCard wearer={wearer} openEditModal={handleOpenEditWearer} />
                {/* Nombre, numero, imei: card principal */}

                {/* Datos principales y Ultima conexion con SoyMomoSIM */}
                <Row gutter={[24, 32]}>

                  {/* Datos principales */}
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <WearerInfo
                      title="Datos principales"
                      subtitle="Reloj"
                      leftIcon="/images/cs-wearerInfo.svg"
                      leftIconWidth={24}
                      leftIconHeight={29}
                      handleRefresh={handleWearerInfoRefresh}
                      wearer={wearer}
                    />
                  </Col>
                  {/* Datos principales */}

                  {/* Ultima conexion con SoyMomoSIM */}
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Space direction="vertical" size={24} style={{ display: 'flex' }}>

                      {/* Ultima conexion */}
                      <WearerLastConnectionCard title="Última conexión" lastTKQ={wearer.lastTKQ} handleRefresh={handleWearerInfoRefresh} />
                      {/* Ultima conexion */}

                      {/* SoyMomoSIM */}
                      <WearerSIMCard
                        simCard={simData}
                        handleRefresh={handleSIMRefresh}
                        navSimDashboard={() => navSimDashboard(imei)}
                      />

                      {/* SoyMomoSIM */}

                    </Space>
                  </Col>
                  {/* Ultima conexion con SoyMomoSIM */}

                </Row>
                {/* Datos principales y Ultima conexion con SoyMomoSIM */}

                {/* Historial de bateria */}
                {/* Historial de bateria */}

              </Space>

            </Col>

            <Col xs={24} sm={12} md={12} lg={8} xl={8}>

              {/* Dimensiones 120 + 24 + 400 + 24 + 400 = 968 */}
              <Space direction="vertical" size={24} style={{ display: 'flex' }}>

                {/* Ultima actualizacion */}
                <AppVersionsCard versionAndroid="5.2.6" versionApple="5.2.6" />
                {/* Ultima actualizacion */}

                {/* Comandos */}
                <ComandsComponent
                  leftIcon='/images/cs-comands.svg'
                  title='Comandos'
                  subtitle='Modificar'
                  leftIconWidth={24}
                  leftIconHeight={24}
                  imei={wearer.imei}
                  deviceId={wearer.deviceId}
                  resetWatch={resetWatch}
                  openMessageApi={openMessageApi}
                  tcpOptions={options}
                  watchInfo={{wearer, users: users.length, friends: friendData.length, userMessages: userMessageData.length, friendMessages: friendMessageData.length}}
                  hardwareModel={wearer.hardwareModel}
                  buildInfoId ={watchBuildData.buildNumber}
                />
                {/* Comandos */}


                {/* Ajustes reloj */}
                <WearerSettings
                  title="Ajustes reloj"
                  subtitle="Configuración"
                  leftIcon="/images/cs-wearerSettings.svg"
                  leftIconWidth={24}
                  leftIconHeight={29}
                  handleRefresh={handleWearerInfoRefresh}
                  watchSettings={watchSettings}
                  editWatchSettings={editWatchSettings}
                  />
                {/* Ajustes reloj */}

              </Space>

            </Col>
          </Row>

          <Space direction="vertical" size={12} style={{ display: 'flex' }}>

          <Row>
          <TableComponent
                columns={watchUserColumnsAux}
                data={users}
                leftIcon="/images/tableIcons/cs-usersIcon.svg"
                leftIconHeight={29}
                leftIconWidth={38}
                handleRefresh={handleWatchUserRefresh}
                title='Usuarios'
                subtitle='Familiares'
              />
          </Row>

          <Row>
          <WearerContactCard wearer={wearer} tokens={tokens} />
            </Row>
            <Row>
              <TableComponent
                columns={friendsColumns}
                data={friendData}
                leftIcon="/images/tableIcons/cs-friendsHeart.svg"
                leftIconHeight={27}
                leftIconWidth={31}
                handleRefresh={handleFriendsRefresh}
                title='Amigos'
                subtitle='Aprobación'
              />
            </Row>
            {/* <Row gutter={[24, 32]}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <TableComponent
                  columns={wifiColumns}
                  data={wifiData}
                  leftIcon="/images/tableIcons/cs-wifiIcon.svg"
                  leftIconHeight={0}
                  leftIconWidth={32}
                  refreshLink="/api/refresh"
                  title='Historial de conexión'
                  subtitle='Internet'
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <TableComponent
                  columns={friendsColumns}
                  data={friendData}
                  leftIcon="/images/tableIcons/cs-friendsHeart.svg"
                  leftIconHeight={27}
                  leftIconWidth={31}
                  refreshLink="/api/refresh"
                  title='Amigos'
                  subtitle='Aprobación'
                />
              </Col>
            </Row> */}
            <Row gutter={[24, 32]}>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <TableComponent
                  columns={friendMessageColumns}
                  data={friendMessageData}
                  leftIcon="/images/tableIcons/cs-friendMessagesIcon.svg"
                  leftIconHeight={29}
                  leftIconWidth={24}
                  handleRefresh={handleChatWearerRefresh}
                  title='Mensajes de amigos'
                  subtitle='Externos'
                />
              </Col>
              <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <TableComponent
                  columns={friendMessageColumns}
                  data={userMessageData}
                  leftIcon="/images/tableIcons/cs-userMessagesIcon.svg"
                  leftIconHeight={29}
                  leftIconWidth={24}
                  handleRefresh={handleChatUserRefresh}
                  title='Mensajes de usuarios'
                  subtitle='Familiares'
                />
              </Col>
            </Row>
            <Row>
              <WearerBatteryHistory
                    data={batteryHistory}
                    handleRefresh={handleBatteryHistoryRefresh}
                  />
            </Row>
          </Space>
        </Space>
      </div>
    </MainLayout>
  )
}