import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Input, Space } from 'antd';
import { useAuth } from "../authContext";
import styles from "../styles/SimActionsCard.module.css"
import sharedStyles from "../styles/Common.module.css"
import { FaChevronRight } from "react-icons/fa";


export default function SimActionsCard(props) {
    const [subTerminated, setSubTerminated] = useState(false);
    const [subPaused, setSubPaused] = useState(false);
    const [toggleTransferSimModal, setToggleTransferSimModal] = useState(false);
    const [toggleTerminateModal, setToggleTerminateModal] = useState(false);
    const [togglePauseModal, setTogglePauseModal] = useState(false);
    const [cancelSubExpl, setCancelSubExpl] = useState('');
    const [newIccId, setNewIccId] = useState('');
    const { tokens } = useAuth();

    const { TextArea } = Input;

    const {
        openMessageApi,
        simCard,
        handleSIMRefresh
    } = props

    const {
        iccId,
        state = '',
        providerName = ''
    } = simCard

    const leftIcon = '/images/cs-comands.svg';
    const leftIconHeight = 24;
    const leftIconWidth = 24;

    useEffect(() => {
        console.log(props);

        if (state === 'TERMINATED') {
            setSubTerminated(true);
        } else if (state === 'SUSPENDED') {
            setSubTerminated(false);
            setSubPaused(true);
        } else {
            setSubTerminated(false);
            setSubPaused(false);
        }
    }, [state]);

    // --------- Terminate Functions ---------
    // #region
    const showTerminateModal = () => {
        setToggleTerminateModal(true)
    }

    const handleTerminateOk = () => {
        handleTerminateSub()
        setToggleTerminateModal(false)
    }

    const handleTerminateCancel = () => {
        setToggleTerminateModal(false)
    }


    async function handleTerminateSub() {
        try {
            openMessageApi('Loading...', 'loading');
            if (!iccId) {
                openMessageApi('iccId inexistente', 'error')
            } else {
                const terminatePayload = { iccId, reason: cancelSubExpl }
                const response = await axios.put(
                    process.env.REACT_APP_BACKEND_HOST + '/subscription/terminate',
                    terminatePayload,
                    {
                        headers:
                            { Authorization: `Bearer ${tokens.AccessToken}` }
                    }
                );
                if (response.status === 201) {
                    setSubTerminated(true)
                    handleSIMRefresh()
                    openMessageApi('Success!', 'success');
                } else {
                    openMessageApi(response.data?.message || 'Error desconocido', 'error')
                }
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'Error desconocido';
            openMessageApi(errorMessage, 'error');
        }
    }
    // #endregion
    // --------- End Terminate Functions ---------

    // --------- Pause Functions ---------
    // #region
    const showPauseModal = () => {
        setTogglePauseModal(true)
    }

    async function handlePauseOk() {
        await handlePauseSub()
        setTogglePauseModal(false)
    }

    const handlePauseCancel = () => {
        setTogglePauseModal(false)
    }


    async function handlePauseSub() {
        try {
            openMessageApi('Loading...', 'loading');
            if (!iccId) {
                openMessageApi('iccId inexistentes', 'error')
            } else {
                let path;
                if (subPaused || subTerminated) {
                    path = '/subscription/resume';
                } else {
                    path = '/subscription/pause';
                }
                const pausePayload = { iccId }
                const response = await axios.put(
                    process.env.REACT_APP_BACKEND_HOST + path,
                    pausePayload,
                    {
                        headers:
                            { Authorization: `Bearer ${tokens.AccessToken}` }
                    }
                );
                if (response.status === 201) {

                    if (path === '/subscription/resume') {
                        setSubPaused(false);
                        setSubTerminated(false);
                    } else {
                        setSubPaused(true);
                        setSubTerminated(false);
                    }
                    openMessageApi('Success!', 'success');

                } else {
                    openMessageApi(`${response.message}`, 'error')
                }
            }
        } catch (error) {
            openMessageApi(`${error.message}`, 'error')
        }
    }
    // #endregion
    // --------- End Pause Functions ---------

    const handleChangeCancelExpl = (e) => {
        setCancelSubExpl(e.target.value)
    }

    const handleNewIccIdChange = async (e) => {
        setNewIccId(e.target.value)
    }

    async function onTransferToNewSims() {
        if (newIccId.length < 20 || newIccId.length > 20) {
            openMessageApi('Ingrese un IccId válido', 'error')
            return;
        }

        if (newIccId === iccId) {
            openMessageApi('El IccId de la nueva SIM no puede ser igual al actual', 'error')
            return;
        }
        setToggleTransferSimModal(true)
    }

    async function handleTransferSimOk() {
        try {
            openMessageApi('Loading...', 'loading');
            const transferPayload = { currentIccId: iccId, newIccId }
            const response = await axios.put(
                process.env.REACT_APP_BACKEND_HOST + '/subscription/transferToNewSim',
                transferPayload,
                {
                    headers:
                        { Authorization: `Bearer ${tokens.AccessToken}` }
                }
            );
            if (response.status === 200) {
                openMessageApi('Success!', 'success');
            } else {
                openMessageApi(`${response.message}`, 'error')
            }
        } catch (error) {
            openMessageApi(`${error.message}`, 'error')
        }
        setToggleTransferSimModal(false)
    }

    async function handleTransferSimCancel() {
        setToggleTransferSimModal(false)
    }

    return (
        <div className={styles.generalContainer}>
            <div className={sharedStyles.generalCard}>
                <div className={sharedStyles.cardSubContainer}>
                    <div className={sharedStyles.flexCenter}>
                        <div className={sharedStyles.iconContainer}>
                            <img src={leftIcon} width={leftIconWidth} height={leftIconHeight} alt='SoyMomo Logo' />
                        </div>
                        <div className={sharedStyles.flexAndCol}>
                            <h1 className={sharedStyles.iconTitle}>Comandos SIM</h1>
                            <p className={sharedStyles.iconSubTitle}>Ejecutar</p>
                        </div>
                    </div>
                </div>
                <div className={sharedStyles.metaData}>
                    <h3 className={styles.comandTitle2}><strong>Transferir SIM</strong></h3>
                    <Space.Compact className={styles.inputContainer}>
                        {/* eslint-disable-next-line react/no-unknown-property */}
                        <input placeholder="Ingrese el IccId de la nueva suscripción" onChange={handleNewIccIdChange} value={newIccId} onKeyDown={(e) => e.key === 'Enter' && onTransferToNewSims()} className={styles.textBox} />
                        <div className={styles.space} />
                        <div className={styles.sendIcon}><FaChevronRight onClick={onTransferToNewSims} /></div>
                    </Space.Compact>

                    <Modal
                        title="Transferir SIM"
                        open={toggleTransferSimModal}
                        onOk={handleTransferSimOk}
                        onCancel={handleTransferSimCancel}
                        okButtonProps={{ className: styles.okBtn }}
                        cancelButtonProps={{ className: styles.cancelBtn }}
                        // Add your custom styles here
                        // bodyStyle={{ /* Your custom styles */ }}
                        // You can also add a className and define your styles in a CSS file
                        className="my-custom-modal-class"
                    >
                        Estás seguro/a que quieres transferir la suscripción a una nueva SIM?
                        Desde: {iccId} Hacia: {newIccId}
                    </Modal>

                    <div className='mt-8 mb-6 flex flex-col items-center'>
                        <div className='flex justify-center w-full space-x-4'>
                            <button disabled={subTerminated || subPaused} onClick={showTerminateModal} className={styles.shutDownBtn}><strong>Cancelar</strong></button>
                    
                            <button disabled={(!subTerminated && providerName !== "ENTEL") || !subPaused} onClick={showPauseModal} className={styles.pausedBtn}><strong>Reanudar</strong></button>
                    
                            <button disabled={subTerminated || subPaused} onClick={showPauseModal} className={styles.shutDownBtn}><strong>Pausar</strong></button>
                        </div>
                    </div>


                    <Modal
                        title="Cancelar Suscripción"
                        open={toggleTerminateModal}
                        onOk={handleTerminateOk}
                        onCancel={handleTerminateCancel}
                        okButtonProps={{
                            className: styles.okBtn,
                            disabled: !cancelSubExpl
                        }}
                        cancelButtonProps={{ className: styles.cancelBtn }}
                        className="my-custom-modal-class"
                    >
                        Estás seguro/a que quieres cancelar la suscripción? Esta acción no se puede deshacer.
                        <br />
                        Escribe una justificación de la cancelación:
                        <TextArea
                            rows={4}
                            placeholder="Justificación de la cancelación"
                            onChange={handleChangeCancelExpl}
                            value={cancelSubExpl}
                            className={styles.textArea}
                        />
                    </Modal>
                    <Modal
                        title="Pausar Suscripción"
                        open={togglePauseModal}
                        onOk={handlePauseOk}
                        onCancel={handlePauseCancel}
                        okButtonProps={{ className: styles.okBtn }}
                        cancelButtonProps={{ className: styles.cancelBtn }}
                        className="my-custom-modal-class"
                    >
                        {
                            subPaused || subTerminated ? 'Estás seguro/a que quieres reanudar la suscripción?' : 'Estás seguro/a que quieres pausar la suscripción?'
                        }
                    </Modal>
                </div>
            </div>
        </div>
    )
}

